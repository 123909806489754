.card-fleet {
  margin-bottom: 3rem;
  
  > .link {
    display: flex;
    
    &:hover {
      opacity: .8;
    }
  }
}