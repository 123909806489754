.-home {
  .card-service {
    > .link {
      color: $white;
    }
  }
}

.card-service {
  margin-bottom: 3rem;

  > .link {
    display: flex;
    color: $preto100;
    text-decoration: none;
    transition: 0.3s;
    padding: 3rem 0;

    &:hover {
      text-decoration: underline;
    }

    > .icon {
      width: 6rem;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary;
      border-radius: 50%;
      margin-right: 1.5rem;

      > img {
        width: 3rem;
        height: 3rem;
      }
    }

    > .text {
      flex: 1;

      > .title {
        font-size: 2.4rem;
        line-height: 1.46;
        margin-bottom: 1rem;
      }
    }
  }
}
