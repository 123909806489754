.the-content {
  padding: 6rem 0;
  min-height: 40rem;

  h2,
  h3 {
    font-weight: bold;
    line-height: 1.463;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  p {
    margin-bottom: 2.6rem;
  }
}
