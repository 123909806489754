/* SLICK SLIDER */

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-prev,
.slick-next {
  z-index: 1;
  width: 6rem;
  height: 6rem;

  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.slick-prev {
  background: rgba($black, .3) url("./images/chevron-left.svg") center no-repeat;
  background-size: 1.5rem 3rem;

  &:hover, &:focus {
    background: rgba($black, .3) url("./images/chevron-left.svg") center no-repeat;
    background-size: 1.5rem 3rem;
  }

  &:hover {
    opacity: .8;
  }
}

.slick-next {
  background: rgba($black, .3) url("./images/chevron-right.svg") center no-repeat;
  background-size: 1.5rem 3rem;

  &:hover, &:focus {
    background: rgba($black, .3) url("./images/chevron-right.svg") center no-repeat;
    background-size: 1.5rem 3rem;
  }

  &:hover {
    opacity: .8;
  }
}

.slider-depositions {
  .slick-prev {
    left: -6rem;
  }
  
  .slick-next {
    right: -6rem;
  }

  .slick-prev {
    background: url("./images/chevron-left-depositions.svg") center no-repeat;
  
    &:hover, &:focus {
      background: url("./images/chevron-left-depositions.svg") center no-repeat;
    }
  }

  .slick-next {
    background: url("./images/chevron-right-depositions.svg") center no-repeat;
  
    &:hover, &:focus {
      background: url("./images/chevron-right-depositions.svg") center no-repeat;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-dots {
  bottom: 1.5rem;
}

.slick-dotted.slick-slider {
  margin: 0;
}

.slick-dots li {
  &.slick-active {
    button {
      background-color: $primary;
    }
  }

  button {
    border: none;
    background-color: $preto30;
    border-radius: 20px;

    &::before {
      display: none;
    }
  }
}

/* CONTACT FORM 7 */

.wpcf7-form-control-wrap {
  display: block;
  margin-bottom: 1.5rem;
}

.wpcf7 form .wpcf7-response-output {
  margin: 1.5rem 0 !important;
}

.wpcf7 .ajax-loader {
  position: absolute !important;
}

/* WORDPRESS */

.wp-block-image img {
  height: auto;
}
