.card-testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;

  > .imagebox {
    margin-bottom: .5rem;

    > .image {
      width: 9rem;
      height: 9rem;
      object-fit: cover;
      padding: .6rem;
      border: .6rem solid $primary;
      border-radius: 50%;
    }
  }

  > .testimonial {
    background: url('./images/left-quote.svg') no-repeat 10rem 0;
    text-align: center;
    margin-bottom: 3rem;
  }

  > .nameandcompany {
    text-align: center;

    > .name {
      font-weight: bold;
    }
  }
}