.slider-app {
  position: relative;
  color: $white;

  .slide {
    position: relative;
    display: flex !important;
    align-items: center;
    min-height: calc(100vh - 8.8rem);
    background: url("./images/bg-slide.png") center no-repeat;
    background-size: cover;
    padding: 6rem 0;

    @media screen and (max-width: $screen-sm) {
      min-height: calc(40vh - 8.8rem);
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.3);
    }

    &.slick-active {
      > .container {
        .content {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }

    > .container {
      position: relative;
      z-index: 1;

      .content {
        opacity: 0;
        transition-duration: 1s;
        transition-delay: .4s;
        transform: translate3d(0, 3rem, 0);
        text-align: center;

        > .title {
          font-size: 4.8rem;
          line-height: 1.46;
          letter-spacing: -1%;
          margin-bottom: 1rem;
          text-transform: uppercase;
        }

        > .text {
          font-size: 3.4rem;
          line-height: 1.46;
          margin-bottom: 3rem;
        }
      }
    }
  }
}
