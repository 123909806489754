$white: #ffffff;
$black: #000000;

$preto100: #464646;
$preto50: #A3A3A3;
$preto30: #C7C7C7;
$preto10: #ECECEC;
$preto05: #F6F6F6;

$primary: #9A3133;
$primary1: #E79092;
$primary2: #BC5558;
$primary3: #721315;
$primary4: #490002;

$secondary: #9B6031;

$mineShaft: #333333;
$mercury: #e5e5e5;
$gray: #808080;

$goldenGrass: #d6a626;
$salomie: #ffdc7f;
$butteredRum: #ab8112;
$brown: #846000;
