.price-app {
  color: white;
  position: relative;
  padding: 6rem 0 3rem;
  background: url('images/bg-cotacao.png') fixed no-repeat center;
  background-size: cover;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary4;
    opacity: .9;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  input, select {
    border: 1px solid $white;
    color: $white;
    background-color: transparent;
  
    &:focus,
    &:active,
    &:hover {
      border-color: $preto50;
    }

    &::placeholder {
      color: $white;
    }
  }
  
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('images/chevron-down-white.svg') no-repeat 98% center;
    padding-right: 30px;

    > option {
      background-color: $primary;
    }
  }
  
  textarea {
    display: block;
    width: 100%;
    height: 10rem;
    border: none;
    border-bottom: 1px solid $gray;
    padding: 2rem 0;
    color: $gray;
    resize: none;
    background-color: transparent;
  
    &:focus,
    &:active,
    &:hover {
      border-color: $mineShaft;
      outline: none;
    }
  }
}