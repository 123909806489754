.form-price {
  padding: 3rem 0;

  .row {
    margin-right: -.75rem;
    margin-left: -.75rem;

    > .col-sm-6 {
      padding: 0 .75rem;
    }
  }
}