html {
  font-size: 10px;

  @media screen and (max-width: $screen-md) {
    font-size: 8px;
  }
}

body {
  font-family: "Humanst521 BT";
  font-size: 1.6rem;
  line-height: 1.625;
  color: $preto100;
}

strong {
  font-weight: bold;
}

a {
  color: $primary;

  &:hover {
    color: $primary4;
  }
}

input, select {
  display: block;
  width: 100%;
  height: 5.6rem;
  border: 1px solid $preto50;
  padding: 1.5rem;
  color: $preto100;
  background-color: $white;
  border-radius: 1rem;

  &:focus,
  &:active,
  &:hover {
    border-color: $preto100;
    outline: none;
  }
}

input[type=submit] {
  border: none;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('images/chevron-down.svg') no-repeat 98% center;
  padding-right: 30px;
}

textarea {
  display: block;
  width: 100%;
  height: 15rem;
  border: 1px solid $preto50;
  padding: 1.5rem;
  color: $preto100;
  resize: none;
  background-color: $white;
  border-radius: 1rem;

  &:focus,
  &:active,
  &:hover {
    border-color: $mineShaft;
    outline: none;
  }
}

.app {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
}

.img-responsive {
  display: block;
  width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.aicenter {
  align-items: center !important;
}

.roundedcorners {
  border-radius: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem!important;
}

.hide {
  display: none !important;
}
