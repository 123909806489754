.contact-map {
  position: relative;
  padding-bottom: 33.82%;
  height: 0;
  line-height: 0;
  overflow: hidden;
  text-indent: -999px;
  border-bottom: 1px solid $preto10;
  margin-top: 3rem;

  > .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}