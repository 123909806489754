.card-article {
  margin-bottom: 3rem;

  > .link {
    display: block;
    margin-bottom: 1.5rem;
    overflow: hidden;

    > img {
      transition: 0.2s;
    }

    &:hover {
      > img {
        transform: scale(1.1);
      }
    }
  }

  > .content {
    padding: 2rem;
    border: 1px solid $mercury;

    > .title {
      font-family: "Playfair Display";
      font-size: 1.8rem;
      line-height: 1.463;
      font-weight: 700;
      margin-bottom: 0.5rem;
      height: 5.2rem;
      overflow: hidden;

      > .link {
        color: $mineShaft;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    > .boxdate {
      font-size: 1.4rem;
      line-height: 1.42;
      display: flex;
      align-items: baseline;
      margin-bottom: 3rem;

      > i {
        margin-right: 0.5rem;
      }
    }

    > .text {
      margin-bottom: 3rem;
    }
  }
}
