.currencyquote-table {
  > .header {
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: .75px;
    text-transform: uppercase;

    > .line {
      display: flex;
      padding: 1.5rem;

      > .column {
        display: flex;
        justify-content: flex-end;
        flex: 1;

        &:first-child {
          flex: none;
          width: 30%;
          justify-content: center;

          @media screen and (max-width: $screen-sm) {
            width: 50%;
          }
        }
  
        > .data {
          width: 100%;
          max-width: 100px;
          text-align: right;
  
          &.-textcenter {
            text-align: center;
          }
        }
      }
    }


  }

  > .body {
    display: flex;
    flex-direction: column;

    > .loading, > .erro, > .quotes {
      > .line {
        display: flex;
        padding: 1.5rem;
        background-color: $white;
        border-radius: 1rem;
        box-shadow: 0 8px 16px rgba($black, .2);
        margin-bottom: 1.5rem;
  
        &:last-child {
          margin-bottom: 0;
        }

        &.-jccenter {
          justify-content: center;
        }
  
        > .column {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex: 1;
  
          &:first-child {
            flex: none;
            width: 30%;
            justify-content: center;
  
            @media screen and (max-width: $screen-sm) {
              width: 50%;
            }
          }
    
          > .currency {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-transform: uppercase;
  
            > .flag {
              display: flex;
              align-items: center;
              
              > img {
                width: 3.2rem;
              }
            }
          }
  
          > .data {
            width: 100%;
            max-width: 100px;
            text-align: right;
          }
        }
      } 
    }
  }
}