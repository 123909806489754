.currencyquote-aside {
  background-color: $primary;
  color: $white;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 8px 16px rgba($black, .2);

  @media screen and (max-width: $screen-sm) {
    margin-bottom: 1.5rem;
  }

  > .title {
    font-size: 2.4rem;
    line-height: 1.46;
    margin-bottom: 1.5rem;
  }
}