.card-new {
  position: relative;
  display: block;
  box-shadow: 0 8px 16px rgba($black, .2);
  text-decoration: none;
  color: $preto100;
  border-radius: 10px;
  overflow: hidden;
  transition: .2s;
  background-color: $white;
  margin-bottom: 3rem;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 16px 32px rgba($black, .2);
    z-index: 100;
  }

  > .imagebox {

    > .image {
      width: 100%;
    }
  }

  > .text {
    padding: 1.5rem;

    > .title {
      font-size: 2.4rem;
      line-height: 1.46;
      font-weight: bold;
      color: $primary;
      margin-bottom: 1.5rem;
    }
  }
}