.customers-logos {
  padding: 6rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $preto10;

  > .imagebox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 0 1rem;

    > .image {
      max-width: 100%;
    }
  }

  @media screen and (max-width: $screen-sm) {
    flex-wrap: wrap;

    > .imagebox {
      flex-basis: 50%;
      padding: 1rem;
    }
  }
}