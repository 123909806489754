.depositions-app {
  margin-bottom: 3rem;
  
  > .title {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
  }
}