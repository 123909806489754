.header-app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba($white, 0.9);
  z-index: 100;
  box-shadow: 0 4px 8px rgba($black, 0.2);
  padding: 1.5rem 0;

  > .container {

    > .content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .logo {
        > .link {
          > .image {
            height: 5rem;
          }
  
          &.desktop {
            @media screen and(max-width: $screen-md) {
              display: none;
            }
          }
  
          &.mobile {
            display: none;
  
            @media screen and(max-width: $screen-md) {
              display: block;
            }
          }
        }
      }
  
      > .nav {
        > .bars {
          font-size: 20px;
          color: $preto100;
          cursor: pointer;
        }
  
        > .menu {
          @media screen and (max-width: $screen-md) {
            position: fixed;
            width: 80%;
            height: 100vh;
            top: 0;
            right: 0;
            background-color: rgba($primary, 0.95);
            box-shadow: 0 8px 16px rgba($black, 0.4);
            transform: translate3d(110%, 0, 0);
            transition: 0.3s;
            overflow-y: scroll;
            z-index: 10;
  
            &.active {
              transform: translate3d(0, 0, 0);
            }
          }
  
          > .close {
            color: $white;
            font-size: 20px;
            padding: 15px;
            z-index: 10;
            text-align: right;
            cursor: pointer;
          }
  
          ul {
            display: flex;
  
            @media screen and (max-width: $screen-md) {
              flex-direction: column;
            }
  
            > li {
              > a {
                position: relative;
                display: block;
                font-weight: bold;
                text-transform: uppercase;
                text-decoration: none;
                font-size: 1.4rem;
                letter-spacing: 0.075rem;
                color: $preto100;
                padding: 1rem;
                transition: 0.3s;

                @media screen and (max-width: $screen-md) {
                  color: $white;
                }
  
                &::after {
                  display: none;
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 1rem;
                  height: 2px;
                  width: calc(100% - 2rem);
                  z-index: 1;
                  background-color: $primary;

                  @media screen and (max-width: $screen-md) {
                    background-color: $primary4;
                  }
                }
  
                &:hover {
                  color: $primary;

                  @media screen and (max-width: $screen-md) {
                    color: $white;
                  }
  
                  &::after {
                    display: block;
                  }
                }
              }
  
              &.current-menu-item,
              &.current-page-ancestor {
                > a {
                  color: $primary;

                  @media screen and (max-width: $screen-md) {
                    color: $primary4;
                  }

                  &::after {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}
