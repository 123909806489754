@font-face {
  font-family: "Humanst521 BT";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fonts/Humanst521 BT Roman.ttf") format("truetype");
}

@font-face {
  font-family: "Humanst521 BT";
  font-style: normal;
  font-weight: bold;
  font-display: block;
  src: url("fonts/Humanst521 BT Bold.ttf") format("truetype");
}
